import * as React from "react";
import Layout from "../Layout";
import * as styles from "./Examples.module.scss";

interface ExampleProps {
  title: string;
}

const Example = ({ title }: ExampleProps) => (
  <div className={styles.exampleContainer}>
    <h1 className={styles.exampleTitle}>{title}</h1>
    <img
      alt={`example for ${title}`}
      src={`/images/examples/examples_${title}.png`}
      className={styles.exampleImg}
    />
  </div>
);

const EXAMPLES = [
  "contains",
  "count",
  "done",
  "get",
  "indexOf",
  "reverse",
  "sort"
];

const Examples = () => (
  <Layout>
    <div className={styles.container}>
      {EXAMPLES.map((title) => (
        <Example key={title} title={title} />
      ))}
    </div>
  </Layout>
);
export default Examples;
